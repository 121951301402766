import React, { useState } from "react";
import styled from "styled-components";
import StarRatings from "react-star-ratings";
import { Colors } from "../../utils/colors";
import { Link } from "gatsby";
import { Iprops } from "../../interface";
import {
  ArrowGray,
  ArrowOrange,
  ArrowPurple,
  FollowersIcon,
  LightPurple,
  LightSkin,
  LightYellow,
  LikesIcon,
  ViewsIcon,
} from "../../assets/svg";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "../Button/button";

const Heading = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 0.0125em;
  color: #3d3d3d;
  @media (max-width: 575px) {
    font-size: 18px;
  }
`;

const Paragraph = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.0125em;
  color: #676767;
  @media (max-width: 767px) {
    padding-top: 33px;
    line-height: 28px;
    font-size: 15.8489px;
    margin-top: 0px;
  }

  @media (max-width: 575px) {
    font-size: 14px;
  }
`;

const CardWrapper = styled.div`
  background-color: ${(props) => props.color};
  padding: 55px 40px 35px;
  border-radius: 10px;
  position: relative;
  height: 100%;
  /* width: 100%; */
  /* max-width: 416px; */
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

  @media (max-width: 991px) {
    margin: 50px auto;
    padding: 50px 25px 25px;
    max-width: 342px;
    height: 100%;
    max-height: 500px;
  }
  @media (max-width: 575px) {
    margin-top: 50px;
    margin-bottom: 0;
    padding: 55px 12px 35px;
    height: 100%;
    max-height: 510px;
    /* min-width: 300px; */
  }
`;

const InnerCard = styled.div`
  color: ${(props) => props.color};
  text-align: center;
`;

const RatingHeading = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.0125em;
  margin-bottom: 0;
  color: #676767;
  @media (max-width: 575px) {
    font-size: 11.8655px;
  }
`;

const VectorImg = styled.div`
  position: absolute;
  top: -50px;
  left: 33px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .img {
    position: relative;
    right: 63px;
  }
`;

const Pricing = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.0125em;
  @media (max-width: 575px) {
    font-size: 11.8655px;
  }
`;

const OuterDiv = styled.div`
  overflow: hidden;
`;

const PackageCard = ({ data }: Iprops) => {

  
  return (
    <div className="col-12 col-md-6 col-lg-4 px-0 px-lg-3 mb-4 mb-md-0 m-md-0">
      <CardWrapper className="d-flex flex-column justify-content-between">
        <InnerCard>
          <div>
            <VectorImg>
              <ViewsIcon />
              <GatsbyImage
                alt={""}
                image={data?.icon?.localFile?.childImageSharp?.gatsbyImageData}
                className="img"
              />
            </VectorImg>
          </div>
          <Heading>{data.title}</Heading>
          <Paragraph>{data.description}</Paragraph>
        </InnerCard>
        <OuterDiv
          color={data.textColor}
          className="align-items-center text-center position-relative"
        >
          <div className="mt-3">
            <StarRatings
              numberOfStars={5}
              rating={5}
              starDimension="25px"
              starSpacing="5px"
              starRatedColor={Colors.primaryColor}
              starHoverColor={Colors.primaryColor}
            />

            <RatingHeading className="pt-3">
              {`${data.star} star rated by customer`}
            </RatingHeading>
          </div>

          <Pricing className="my-2">{data.rate}</Pricing>
          <Link
            to={data.buttonLink}
            className="align-items-center text-decoration-none"
          >
            <Button
              text={data.buttonText}
              style={{ width: "200px", margin: "0 auto" }}
            />
          </Link>
        </OuterDiv>
      </CardWrapper>
    </div>
  );
};

export default PackageCard;
